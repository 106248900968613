@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,300,401,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .custom-scrollbar ::-webkit-scrollbar {
    width: 10px;
  }
  

  .custom-scrollbar ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   

  .custom-scrollbar ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  

  .custom-scrollbar ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  html{
    /* overflow: hidden; */
  }